import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import CustomEase from 'gsap/CustomEase'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(SplitText)
}

const headerAnim = (ref) => {
  const sT = new SplitText(ref.current, {
    type: 'words,chars',
  })

  gsap.from(sT.chars, {
    duration: 0.8,
    ease: 'circ.out',
    yPercent: 100,
    opacity: 0,
    stagger: 0.03,
    delay: 0.2,
  })
}

const isTouch = () =>
  'ontouchstart' in document.documentElement || navigator.maxTouchPoints

const fixhHeight = (state /* , page */) => {
  if (!isTouch()) {
    // console.count(`${state} - ${page}`)
    const sm = ScrollSmoother.get()

    if (typeof window !== 'undefined') {
      if (state === 'exiting') {
        sm.paused(true)
        // sm.scrollTrigger.refresh()
      }

      if (state === 'exited' || state === 'entering') {
        // sm.scrollTo(0)
        sm.scrollTrigger.refresh()
      }

      if (state === 'exited') {
        sm.scrollTo(0)
        // sm.scrollTrigger.refresh()
      }

      if (state === 'entered') {
        sm.scrollTo(0)
        sm.paused(false)
        sm.scrollTrigger.refresh()
        ScrollTrigger.refresh()
      }
      // window.dispatchEvent(new Event('resize'))
      // ScrollTrigger.refresh(true)
    }
  }
}

const hzScrollAnim = (className, screenWidth, wrapperId, variant, start) => {
  const item = document.querySelector(`.${className}`)

  const sT = ScrollTrigger.getById(`horizontal-${variant}`)

  if (!sT) {
    gsap.to(item, {
      x: () => `${-item.clientWidth + screenWidth}px`,
      ease: 'none',
      scrollTrigger: {
        trigger: `.${className}`,
        start: start || `bottom 99%`,
        id: `horizontal-${variant}`,
        pin: `#${wrapperId}`,
        refreshPriority: 3,
        scrub: 1,
        end: () =>
          `${
            screenWidth >= 769 ? item.clientWidth / 3 : item.clientWidth / 1.5
          }px`,
      },
    })
  }

  setTimeout(() => ScrollTrigger.refresh(), 0)
}

const killHzScroll = (variant) => {
  ScrollTrigger.getById(`horizontal-${variant}`)?.kill()
}

const ballFollowAnim = () => {
  if (!isTouch()) {
    gsap.set('.ball', { xPercent: -50, yPercent: -50 })

    const ball = document.querySelector('.ball')
    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
    const mouse = { x: pos.x, y: pos.y }
    const speed = 0.2

    const fpms = 60 / 1000

    const xSet = gsap.quickSetter(ball, 'x', 'px')
    const ySet = gsap.quickSetter(ball, 'y', 'px')

    window.addEventListener('mousemove', (e) => {
      mouse.x = e.x
      mouse.y = e.y
    })

    gsap.ticker.add((time, deltaTime) => {
      const delta = deltaTime * fpms
      const dt = 1.0 - (1.0 - speed) ** delta

      pos.x += (mouse.x - pos.x) * dt
      pos.y += (mouse.y - pos.y) * dt
      xSet(pos.x)
      ySet(pos.y)
    })

    const sect = document.querySelector('#gatsby-focus-wrapper')

    sect.onmouseenter = () => {
      gsap.to(ball, { scale: 1 })
    }

    sect.onmouseleave = () => {
      gsap.to(ball, { scale: 0 })
    }
  }
}

const ballChangeColor = ({ e, ...props }) => {
  const defaults = {
    duration: 0.3,
    ease: CustomEase.create('custom', 'M0,0 C0.25,0.1 0.46,1 1,1 '),
  }

  const onMouseEnter = () => {
    gsap.to('.ball', {
      ...defaults,
      ...props,
    })
  }

  const onMouseLeave = () => {
    gsap.to('.ball', {
      ...defaults,
      backgroundColor: '#fff',
      mixBlendMode: 'difference',
    })
  }

  e.addEventListener('mouseenter', onMouseEnter)
  e.addEventListener('mouseleave', onMouseLeave)

  return () => {
    e.addEventListener('mouseenter', onMouseEnter)
    e.addEventListener('mouseleave', onMouseLeave)
  }
}

const setBallColor = (props) => {
  gsap.set('.ball', {
    ...props,
  })
}

const resetBallColor = () => {
  gsap.set('.ball', {
    backgroundColor: '#fff',
    mixBlendMode: 'difference',
  })
}

// eslint-disable-next-line import/prefer-default-export
export {
  headerAnim,
  fixhHeight,
  isTouch,
  hzScrollAnim,
  killHzScroll,
  ballFollowAnim,
  ballChangeColor,
  setBallColor,
  resetBallColor,
}
