import React, { useEffect, useState } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import cn from 'classnames'
import { isTouch } from '~utils'
import * as s from './style.module.scss'

const CloseProject = ({ fixed }) => {
  const [state, setState] = useState(false)

  const onScroll = () => {
    if (window.scrollY > 20) {
      setState(true)
    } else {
      setState(false)
    }
  }

  useEffect(() => {
    setState(fixed)
  }, [fixed])

  useEffect(() => {
    setState(false)
    if (isTouch()) {
      window.addEventListener('scroll', onScroll)
    }
    return () => isTouch() && window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <AniLink
      className={cn(s.close, {
        [s.fixed]: state,
      })}
      to="/cases"
      paintDrip
      color="white"
    />
  )
}

export default CloseProject
