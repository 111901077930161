import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import gsap from 'gsap'

import Icon from '~components/Icon'
import * as s from './style.module.scss'

const ScrollTopButton = (props) => {
  const { className, ...rest } = props
  const [showButton, setShowButton] = useState(false)

  const handleScroll = () => {
    if (window.scrollY > 700) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }

  const scrollToTop = () => {
    gsap.to(window, { scrollTo: 0, ease: 'power2' })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <button
      type="button"
      {...rest}
      onClick={scrollToTop}
      className={cn(s.scrollTopButton, { [s.show]: showButton }, className)}
    >
      <Icon name="arrow-up" size={44} />
    </button>
  )
}

ScrollTopButton.defaultProps = {
  className: '',
}

ScrollTopButton.propTypes = {
  className: PropTypes.string,
}

export default ScrollTopButton
