const MENU = [
  {
    name: 'Work',
    link: '/cases',
  },
  {
    name: 'About',
    link: '/about',
  },
  {
    name: 'Team',
    link: '/team',
  },
  {
    name: 'Contact',
    link: '/contact',
  },
]

export default MENU
