import React from 'react'
import { Container } from 'react-bootstrap'
import { withPrefix } from 'gatsby'
import { useLocation } from '@gatsbyjs/reach-router'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import Social from '~components/Social'

import * as s from './style.module.scss'

const Footer = () => {
  const year = new Date().getFullYear()
  const location = useLocation()
  const isHomepage = location.pathname === withPrefix('/')

  return (
    <Container as="footer" className={s.footer}>
      <div className={s.footer_copy}>© Minimal LLC, 2017-{year}</div>
      <div className={s.footer_title}>
        <AniLink
          paintDrip
          color="black"
          to="/"
          style={{ pointerEvents: isHomepage ? 'none' : 'auto' }}
        >
          MINIMAL
        </AniLink>
      </div>
      <Social />
    </Container>
  )
}

export default Footer
