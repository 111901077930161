/* eslint-disable no-param-reassign, import/newline-after-import */
import gsap from 'gsap'

if (typeof window !== 'undefined') {
  ;(() => {
    const blurProperty = gsap.utils.checkPrefix('filter')
    const blurExp = /blur\((.+)?px\)/
    const getBlurMatch = (target) =>
      (gsap.getProperty(target, blurProperty) || '').match(blurExp) || []

    gsap.registerPlugin({
      name: 'blur',
      get(target) {
        return +getBlurMatch(target)[1] || 0
      },
      init(target, endValue) {
        const data = this
        let filter = gsap.getProperty(target, blurProperty)
        const endBlur = `blur(${endValue}px)`
        const match = getBlurMatch(target)[0]
        let index
        if (filter === 'none') {
          filter = ''
        }
        if (match) {
          index = filter.indexOf(match)
          endValue =
            filter.substr(0, index) +
            endBlur +
            filter.substr(index + match.length)
        } else {
          endValue = filter + endBlur
          filter += filter ? ' blur(0px)' : 'blur(0px)'
        }
        data.target = target
        data.interp = gsap.utils.interpolate(filter, endValue)
      },
      render(progress, data) {
        data.target.style[blurProperty] = data.interp(progress)
      },
    })
  })()
}
