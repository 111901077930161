import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { useLocation } from '@gatsbyjs/reach-router'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { Container } from 'react-bootstrap'

import Icon from '~components/Icon'
import * as s from './style.module.scss'

const getTime = (timezone) => {
  return new Date()
    .toLocaleString('en-US', {
      timeZone: timezone,
      timeStyle: 'short',
      hourCycle: 'h12',
    })
    .split(' ')
}

const ContactUs = ({ title, timezones }) => {
  const [timer, setTimer] = useState('')

  const { pathname } = useLocation()
  const isContact = pathname === '/contact'

  const clocks = timezones.map((tz) => {
    const time = getTime(tz.timezone)

    return (
      <li key={tz.country} className={s.timezone}>
        <span
          className={s.timezone_time}
          data-format={time[1]}
          data-country={tz.country}
        >
          {time[0].length === 4 ? `0${time[0]}` : time[0]}
        </span>
      </li>
    )
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(new Date())
    }, 60000)

    return () => clearInterval(interval)
  }, [timer])

  return (
    <Container className={s.contact}>
      {!isContact && (
        <h1 className={s.contact_title}>
          {title.map((part) =>
            part.href ? (
              <AniLink
                key={part.text}
                to={part.href}
                fade
                duration={2}
                className={s.contact_shareWrapper}
              >
                <span
                  className={cn({
                    [s[part.variant]]: part.variant,
                  })}
                >
                  {part.text}
                </span>
                <span className={s.contact_shareIcon}>
                  <Icon name="arrow-share" size={32} />
                </span>
              </AniLink>
            ) : (
              <span
                key={part.text}
                className={cn({
                  [s[part.variant]]: part.variant,
                })}
              >
                {part.text}
              </span>
            )
          )}
        </h1>
      )}
      <ul className={s.contact_timezones}>{clocks}</ul>
    </Container>
  )
}

export default ContactUs
