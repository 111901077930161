const SOCIAL = [
  {
    name: 'linkedin',
    link: 'https://www.linkedin.com/company/min-studio-llc/',
  },
  {
    name: 'upwork',
    link: 'https://www.upwork.com/ag/minimal/',
  },
  {
    name: 'instagram',
    link: 'https://instagram.com/min.studio.llc',
  },
  {
    name: 'dribbble',
    link: 'https://dribbble.com/minstudio',
  },
]

export default SOCIAL
