import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Nav } from 'react-bootstrap'
import cn from 'classnames'
import gsap from 'gsap'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import MENU from './constants'

const Menu = ({ variant, handleClose }) => {
  const itemsRef = useRef([])

  useEffect(() => {
    const params =
      variant !== 'offcanvas'
        ? {
            x: 10,
            stagger: 0.1,
            delay: 1,
            duration: 2,
          }
        : { x: 10, stagger: 0.15, duration: 1 /* ease: 'power2.inOut' */ }

    gsap.from(itemsRef.current, {
      // ease: 'circ.out',
      opacity: 0,
      ...params,
      // onComplete: () => part.split.revert(),
    })
  }, [])

  return (
    <Nav className={cn({ [`nav--${variant}`]: variant })} as="ul">
      {MENU.map(({ name, link }, i) => (
        <Nav.Item
          as="li"
          key={name}
          ref={(el) => {
            itemsRef.current[i] = el
          }}
        >
          <Nav.Link
            as={AniLink}
            to={link}
            cover
            bg="black"
            // direction="right"
            activeClassName="active"
            // swipe
            direction="up"
            onClick={handleClose && handleClose}
          >
            {name}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  )
}

Menu.defaultProps = {
  variant: '',
}

Menu.propTypes = {
  variant: PropTypes.string,
}

export default Menu
