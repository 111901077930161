import React, { useState, useEffect, useRef } from 'react'
import { Container, Offcanvas } from 'react-bootstrap'
import { useLocation } from '@gatsbyjs/reach-router'
import { withPrefix } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import gsap from 'gsap'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import SplitText from 'gsap/SplitText'
import cn from 'classnames'
import '~plugins/blurText'

import Menu from '~components/Menu'

import * as s from './style.module.scss'

const morphSVG =
  typeof document !== 'undefined' ? require('gsap/MorphSVGPlugin') : null

gsap.registerPlugin(SplitText)
if (morphSVG) gsap.registerPlugin(morphSVG)

const Header = () => {
  const [show, setShow] = useState(null)
  const [tlMenu, settlMenu] = useState()

  const location = useLocation()
  const isHomepage = location.pathname === withPrefix('/')

  const isDesktop = useMediaQuery({ query: '(min-width: 1240px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })

  const linkRef = useRef()
  const logoRef = useRef()
  const hambRef = useRef()

  useEffect(() => {
    const mySplitText = new SplitText(logoRef.current, { type: 'chars' })
    const tl = gsap.timeline({
      paused: true,
      onComplete: () => {
        mySplitText.revert()
      },
    })
    // const numWords = mySplitText.chars.length

    // due to bugs in Chrome that caused vibrating while scaling (unrelated to GSAP), we had to delay the start by a brief moment (to let it render at full size once), set will-change:transform, and then animate things in. SUPER annoying. Hopefully Chrome will fix it soon.
    // gsap.delayedCall(0.08, () => {
    mySplitText.chars.forEach((ch) => {
      tl.from(
        ch,
        1,
        {
          force3D: true,
          scale: Math.random() > 0.5 ? 0 : 3,
          opacity: 0,
          blur: 10,
          ease: 'circ.out',
          // clearProps: 'all',
        },
        Math.random()
      )
    })
    tl.play()
    // })

    gsap.from(linkRef.current, {
      opacity: 0,
      x: -20,
      // ease: 'circ.out',
      duration: 2,
      delay: 1,
    })

    gsap.from(hambRef.current, {
      opacity: 0,
      x: 20,
      // ease: 'circ.out',
      duration: 2,
      delay: 1,
    })
  }, [])

  useEffect(() => {
    settlMenu(
      gsap
        .timeline({ paused: true })
        .to(
          '#menuOpen',
          {
            morphSVG: {
              shape: '#menuClose',
              // type: 'rotational',
            },
            duration: 0.6,
            // repeat: 1,
            // yoyo: true,
            // repeatDelay: 0.2,
            ease: 'power1.inOut',
          },
          0
        )
        .to(
          '#menuSvg',
          {
            blur: 1,
            duration: 0.3,
          },
          0
        )
        .to(
          '#menuSvg',
          {
            blur: 0,
            duration: 0.3,
          },
          0.3
        )
    )
  }, [])

  const handleMenu = (state) => {
    if (state) {
      tlMenu.play()
    } else {
      tlMenu.reverse()
    }
    setShow(state)
  }

  const handleMenuClose = () => handleMenu(false)
  const handleMenuToggle = () => handleMenu(!show)
  const handleLogoClick = () => setTimeout(handleMenuClose, 500)

  return (
    <Container as="header" className={s.header}>
      <a
        className={s.header_link}
        ref={linkRef}
        href="mailto:hi@min.studio?subject=Estimate project"
        target="_blank"
        rel="noreferrer"
      >
        Estimate{!isMobile && ' project'}
      </a>
      <p className={cn(s.header_title, { [s.home]: isHomepage })} ref={logoRef}>
        {isHomepage ? (
          'MINIMAL'
        ) : (
          <AniLink
            paintDrip
            color="black"
            to="/"
            onClick={show && handleLogoClick}
          >
            MINIMAL
          </AniLink>
        )}
      </p>
      <Menu variant="header" />
      <button
        className={s.header_hamb}
        type="button"
        aria-label="Menu"
        onClick={handleMenuToggle}
        ref={hambRef}
      >
        {/* {!show ? 'Menu' : 'Close'} */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 39 13"
          width="39"
          height="13"
          id="menuSvg"
        >
          <path
            id="menuOpen"
            d="m9.6.8-3.3 9.4L2.9.8H.7V12H2V2.5L5.5 12H7l3.4-9.5V12h1.4V.8H9.6Zm11.2 7c0-1.3-.3-2.3-1-3.1A3.5 3.5 0 0 0 17 3.5c-1.1 0-2 .4-2.7 1.2-.7.8-1 1.8-1 3.2 0 1.3.3 2.4 1 3.1.7.8 1.6 1.2 2.7 1.2.9 0 1.7-.3 2.3-.8.6-.5 1-1.2 1.3-2.2h-1.4c-.2.6-.5 1-.9 1.3-.4.3-.8.5-1.3.5-.7 0-1.2-.3-1.7-.8-.4-.5-.7-1.2-.7-2h6.1v-.3Zm-6-.8a3 3 0 0 1 .8-1.6 3 3 0 0 1 1.5-.7c.5 0 1 .3 1.5.7.4.4.7 1 .8 1.6h-4.7Zm7.4-3.3V12h1.4V3.7h-1.4Zm3.6-.1a2 2 0 0 0-1.5.9c-.5.6-.7 1.2-.7 2 0-.5.2-1 .6-1.2a2 2 0 0 1 1.4-.5c.6 0 1 .1 1.4.5.4.3.5.7.5 1.2V12H29V6.5c0-.4 0-.7-.2-1.1l-.6-1-1-.7a3 3 0 0 0-1.3-.1Zm8 7.4c-.6 0-1-.2-1.4-.5-.3-.3-.5-.8-.5-1.3V3.7h-1.4v5.5c0 .5.1 1 .3 1.4l.8 1a3.2 3.2 0 0 0 2 .6c.5 0 .9-.2 1.2-.5.3-.3.5-.7.7-1.2l.2-1.7a2.7 2.7 0 0 1-.4 1.5c-.2.2-.3.4-.6.5l-.8.2Zm2-7.3V12H37V3.7h-1.4Z"
          />
          <path
            id="menuClose"
            d="M5.5 11A3.2 3.2 0 0 1 3 9.6 5 5 0 0 1 2.2 8a6.1 6.1 0 0 1 .2-4 4 4 0 0 1 1.3-1.6c.5-.5 1.2-.7 1.8-.7 1.8 0 3 1 3.4 2.7h1.5L10 3.3a4.7 4.7 0 0 0-1.5-2L7.2.7A6 6 0 0 0 5.5.5c-1 0-1.8.3-2.6.8-.7.5-1.3 1.2-1.7 2.1a7.8 7.8 0 0 0-.3 5.3 6 6 0 0 0 1 1.9c.3.5.9 1 1.5 1.2.6.4 1.3.5 2.1.5a5.1 5.1 0 0 0 2.4-.6 4.6 4.6 0 0 0 1.9-1.9l.5-1.5H8.8c-.3.8-.7 1.5-1.3 2a3 3 0 0 1-2 .7Zm7.6 1V.8h-1.4V12H13Zm5.2-8.5c-1.1 0-2 .4-2.7 1.2-.7.8-1 1.8-1 3.2 0 1.3.3 2.4 1 3.1.7.8 1.6 1.2 2.7 1.2 1 0 2-.4 2.7-1.2.7-.7 1-1.8 1-3.1 0-1.4-.3-2.4-1-3.2a3.5 3.5 0 0 0-2.7-1.2Zm0 7.5c-.7 0-1.3-.3-1.8-.9-.4-.5-.7-1.3-.7-2.2 0-1 .3-1.7.7-2.3.5-.5 1-.8 1.8-.9.7 0 1.2.3 1.7 1 .5.5.7 1.3.7 2.2 0 1-.2 1.7-.7 2.2-.5.6-1 .9-1.7.9Zm7.5-4.1-.8-.4a.8.8 0 0 1-.3-.6c0-.4.2-.6.6-.8.3-.2.7-.4 1.2-.4s1 .2 1.3.4c.3.3.5.6.5 1h1.3c0-.7-.2-1.2-.8-1.8-.6-.5-1.4-.8-2.3-.8-1 0-1.6.3-2.3.8a2 2 0 0 0-.9 1.7l.1.6.4.6.6.6c.2.2.6.3 1 .4l1.1.3.7.2 1 .4.2.6c0 .4-.1.7-.5 1-.3.2-.8.3-1.4.3-.6 0-1.1-.2-1.5-.5-.5-.4-.6-.7-.6-1.2 0-.2.2-.4.4-.6l.8-.3.9.1a7 7 0 0 0-1-.2h-1c-.4 0-.7.2-1 .4l-.3.6v1l.7 1 1 .6a4.7 4.7 0 0 0 4-.3l.7-.8.3-1.2c0-1-.7-1.8-2.2-2.2l-2-.5Zm12.7 1a5 5 0 0 0-1-3.2 3.5 3.5 0 0 0-2.8-1.2c-1 0-2 .4-2.7 1.2-.7.8-1 1.8-1 3.2 0 1.3.3 2.4 1 3.1.7.8 1.6 1.2 2.7 1.2 1 0 1.7-.3 2.3-.8.7-.5 1.1-1.2 1.3-2.2H37a2 2 0 0 1-.8 1.3 2.2 2.2 0 0 1-3-.3c-.5-.5-.7-1.2-.8-2h6.2v-.3ZM32.4 7a3 3 0 0 1 .8-1.6c.5-.4 1-.6 1.5-.7.6 0 1 .3 1.5.7.4.4.7 1 .9 1.6h-4.7Z"
          />
        </svg>
      </button>
      {!isDesktop && (
        <Offcanvas show={show}>
          <Container as={Offcanvas.Body}>
            <div className="offcanvas-nav">
              <p className="offcanvas-title">MENU</p>
              <Menu variant="offcanvas" handleClose={handleMenuClose} />
            </div>
            <p className="offcanvas-footnote">
              <span>SINCE ©2017</span>
            </p>
          </Container>
        </Offcanvas>
      )}
    </Container>
  )
}

export default Header
