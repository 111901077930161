/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@gatsbyjs/reach-router'
import cn from 'classnames'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import ScrollSmoother from 'gsap/ScrollSmoother'
import SplitText from 'gsap/SplitText'

import { ballFollowAnim, isTouch } from '~utils'
import S from '~components/seo'
import Header from '~components/Header'
import Footer from '~components/Footer'
import ContactUs from '~components/ContactUs'
import CloseProject from '~components/CloseProject'
import ScrollTopButton from '~components/Layout/components/ScrollTopButton'

import CustomEase from 'gsap/CustomEase'
import * as s from './style.module.scss'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(
    ScrollTrigger,
    ScrollToPlugin,
    ScrollSmoother,
    SplitText,
    CustomEase
  )
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      homeJson {
        contactUs {
          title {
            text
            variant
            href
          }
          timezones {
            country
            timezone
          }
        }
      }
    }
  `)

  const [fixed, setFixed] = useState(false)
  const [loader, setLoader] = useState(true)
  const [touch, setTouch] = useState(true)

  const location = useLocation()
  const isProject = location.pathname.includes('cases/')

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
      if (!isTouch()) {
        ScrollSmoother.create({
          smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
          // effects: true, // looks for data-speed and data-lag attributes on elements
          // normalizeScroll: true, // prevents showing/hiding of address bar, works around iOS Safari jitter, etc.
          // smoothTouch: false, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
          // ignoreMobileResize: true, // skips ScrollTrigger.refresh() on mobile resizes from address bar showing/hiding
          // preventDefault: true,
          onUpdate: (self) => {
            // eslint-disable-next-line no-unsafe-optional-chaining
            const scrollPos = self.progress * self?.scrollTrigger?.end

            const dir = self?.scrollTrigger?.direction

            if (scrollPos < 60) {
              setFixed(scrollPos > 20 && dir === 1)
            } else {
              setFixed(true)
            }
          },
        })
      }
    }, 1500)

    setTouch(isTouch())

    ScrollTrigger.config({
      ignoreMobileResize: true,
      autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load',
    })
  }, [])

  useEffect(ballFollowAnim, [touch])

  return (
    <div className={cn(s.layout, { [s.ready]: !loader })}>
      {isProject && <CloseProject fixed={fixed} />}
      {!touch && <div className="ball" />}
      <div id="smooth-wrapper">
        <div id="smooth-content">
          {!loader && <Header />}
          <main className="main">
            {loader ? <S title="Welcome!" /> : children}
          </main>
          {!loader && (
            <div className={s.wrapper}>
              <ContactUs {...data.homeJson.contactUs} />
              <Footer />
            </div>
          )}
        </div>
      </div>
      <div className={cn(s.loader, { [s.hide]: !loader })}>
        <div className={s.loader__logo}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
            viewBox="0 0 176 20"
            width="176"
            height={20}
          >
            <path d="M20.6 16.6V2c0-.6.1-1 .3-1.2.2-.2.6-.3 1.2-.3V.2h-3.7l-6.6 15.5L4.8.2H.9v.2h.2c.7 0 1.3.4 1.7 1.3v13c0 2.3-.8 3.4-2.3 3.4H.4v.2h5.2v-.2C3.8 18.1 3 17 3 14.8V2l7.4 16.3H13l-1-2.2L18.4.9v15.7c0 .6-.1 1-.3 1.2-.2.2-.6.3-1.1.3v.2h5v-.2c-.6 0-1 0-1.2-.3-.2-.2-.3-.6-.3-1.2Zm18 0V2c0-.6.1-1 .3-1.2.2-.2.6-.3 1.2-.3V.2H35v.2c.6 0 1 0 1.2.3.2.2.3.6.3 1.2v14.7c0 .6-.1 1-.3 1.2-.2.2-.6.3-1.1.3v.2H40v-.2c-.6 0-1 0-1.2-.3-.2-.2-.3-.6-.3-1.2Zm30.5 1.7-1.5-2.2V3.7c0-2.2.8-3.3 2.4-3.3V.2h-5.2v.2c1.7 0 2.5 1.1 2.5 3.3v12L56.4.2h-3.7v.2c.5 0 .8 0 1 .2l1 1.2v13c0 2.2-.8 3.3-2.3 3.3v.2h5.1v-.2c-1.7 0-2.5-1.1-2.5-3.3V2l11.3 16.2h2.8Zm16.7-1.7V2c0-.6.1-1 .3-1.2.2-.2.6-.3 1.1-.3h.1V.2h-5.2v.2c.6 0 1 0 1.2.3.2.2.3.6.3 1.2v14.7c0 .6-.1 1-.3 1.2-.2.2-.6.3-1.1.3v.2h5.1v-.2c-.6 0-1 0-1.2-.3-.2-.2-.3-.6-.3-1.2Zm33.9 0V2c0-.6 0-1 .3-1.2.2-.2.5-.3 1.1-.3V.2h-3.7L111 15.7 104 .2h-4v.2h.1c.8 0 1.3.4 1.8 1.3v13c0 2.3-.8 3.4-2.4 3.4v.2h5.1v-.2c-1.6 0-2.4-1.1-2.4-3.3V2l7.4 16.3h2.4l-1-2.2L117.4.9v15.7c0 .6 0 1-.3 1.2-.2.2-.6.3-1 .3h-.1v.2h5.2v-.2h-.1c-.6 0-1 0-1.1-.3-.2-.2-.3-.6-.3-1.2Zm30.8 1.7v-.2c-.4 0-.8 0-1.1-.3-.3-.3-.6-.7-.8-1.3L142 .2h-2.5l1 2.3-4.9 12.2c-.4 1.2-1 2-1.5 2.6-.5.5-1.1.8-1.8.8v.2h5.2v-.2c-.7 0-1.2-.1-1.5-.4-.3-.3-.5-.7-.5-1.2 0-.4.1-1 .4-1.6l.6-1.6h8.4l1.3 3.1.2.8c0 .6-.4 1-1.4 1v.1h5.6Zm-10-15.5 4.2 10.2h-8.2l4-10.2Zm35.3 9.3h-.2a43.4 43.4 0 0 1-3.3 4.8 3 3 0 0 1-1.2 1l-1.3.2h-4.2V1.9c0-.6.1-1 .3-1.2.2-.2.6-.3 1.1-.3V.2h-5v.2c.6 0 1 0 1.2.3.2.2.3.6.3 1.2v14.7c0 .6-.2 1-.3 1.2-.3.2-.6.3-1.2.3v.2h10.3l.7.2.2.6h.1l2.6-7Z" />
          </svg>
        </div>
      </div>
      <ScrollTopButton />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
